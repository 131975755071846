import {Component, OnInit} from '@angular/core';
import {HomeComponent} from "../../components/home/home.component";
import {RouterOutlet} from "@angular/router";
import {IonicModule} from "@ionic/angular";
import {MainHeaderComponent} from "../../components/header/main-header/main-header.component";

@Component({
    selector: 'app-home-page',
    templateUrl: 'home.page.html',
    standalone: true,
    imports: [IonicModule, RouterOutlet, HomeComponent, MainHeaderComponent],
})
export class HomePage implements OnInit {
    ngOnInit() {
    }
}
