import {Component, OnInit} from '@angular/core';
import {IonicModule} from "@ionic/angular";

@Component({
    selector: 'app-app-update-notifier',
    templateUrl: './app-update-notifier.component.html',
    standalone: true,
    imports: [IonicModule],
})
export class AppUpdateNotifierComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
