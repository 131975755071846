import {Injectable} from '@angular/core';
import {AlertService} from "./alert.service";
import {HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {NavController} from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {

    constructor(
        private _alert: AlertService,
        private _nav: NavController,
    ) {
    }

    async handle(e: any, customMessage: string = null) {
        await this._handle(e, customMessage);
    }

    async handleWithRedirect(e: any, redirectUrl: string = null, customMessage: string = null) {
        await this._handle(e, customMessage);
        if (redirectUrl) await this._nav.navigateRoot(redirectUrl);
    }

    private async _handle(e: any, customMessage: string = null) {
        if (!environment.production) console.log(e);

        if (customMessage) {
            await this._alert.warn(customMessage);
            return;
        }

        const type = typeof e;

        if (type === 'string') {
            await this._handleString(e);
        } else {
            await this._handleObject(e);
        }
    }

    private async _handleObject(e: HttpErrorResponse): Promise<void> {
        const isValidationError = !!(e as ValidationError).error?.errors;

        if (isValidationError) {
            const errors = (e as ValidationError).error.errors;
            const messages = Object.keys(errors).map(key => errors[key].join('\n'));
            await this._alert.warn(messages.join('\n'));
            return;
        } else {
            let message = typeof e.error === 'string'
                ? e.error
                : typeof e.error?.Error === 'string'
                    ? e.error.Error
                    : e.message;

            if (message == null || typeof message !== 'string') message = 'Something went wrong, please try again';
            await this._alert.warn(message);
        }
    }

    private async _handleString(e: string): Promise<void> {
        await this._alert.warn(e);
    }
}

type ValidationError = {
    error: {
        errors: {
            [key: string]: string[]
        }
    }
}
