import {inject} from '@angular/core';
import {CanActivateFn} from '@angular/router';
import {firstValueFrom} from 'rxjs';
import {AuthService} from "@auth0/auth0-angular";
import {AlertService} from "../services/alert.service";
import {NavController} from "@ionic/angular";

export const authGuard: CanActivateFn = async () => {
    const authService = inject(AuthService);
    const alertService = inject(AlertService);
    const navController = inject(NavController);

    const isAuthenticated = await firstValueFrom(authService.isAuthenticated$);

    if (!isAuthenticated) {
        alertService.warn('Please login to continue');
        navController.navigateRoot('');
    }

    return isAuthenticated;
}
