import {Component, OnInit} from '@angular/core';
import {IonicModule} from "@ionic/angular";
import {MainHeaderComponent} from "../../components/header/main-header/main-header.component";

@Component({
    selector: 'app-cookie-policy',
    templateUrl: './cookie-policy.component.html',
    standalone: true,
    imports: [
        IonicModule,
        MainHeaderComponent
    ]
})
export class CookiePolicyComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
