import {effect, Injectable, signal} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom, lastValueFrom, Subject} from "rxjs";
import {RestrictionsDto} from "../_dtos/values-dtos/restrictions-dto";
import {ErrorHandlerService} from "./error-handler.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {SelectedAddressService} from "./selected-address.service";
import {LoggerService} from "./logger.service";

@Injectable({
    providedIn: 'root'
})
export class ValuesService {
    private _baseUrl = environment.apiUrl + 'values/';
    private _restrictions: RestrictionsDto | undefined = undefined;
    private _identificationCardTypes: string[] | undefined = undefined;

    $identificationCardTypes = signal(this._identificationCardTypes);
    $menuItemCategories = signal<string[] | undefined>(undefined);
    $apiVersion = signal<string | undefined>(undefined);
    $restrictions = signal(this._restrictions);

    constructor(
        private _http: HttpClient,
        private _selectedAddressService: SelectedAddressService,
        private _logger: LoggerService
    ) {
        effect(async () => {
            const storeName = this._selectedAddressService.$storeAssignment();
            await this.loadRestrictionsAsync(storeName);
        });
    }

    async loadMenuItemCategoriesAsync() {
        const categories = await firstValueFrom(this._http.get<string[]>(this._baseUrl + 'menuItemCategories'));
        this.$menuItemCategories.set(categories);
    }

    async loadIdentificationCardTypesAsync() {
        const cardTypes = await firstValueFrom(this._http.get<string[]>(this._baseUrl + 'identificationCardTypes'));
        this.$identificationCardTypes.set(cardTypes);
    }

    async getApiVersionAsync() {
        return await firstValueFrom(this._http.get<{ version: string }>(this._baseUrl + 'ApiVersion'));
    }

    async loadRestrictionsAsync(storeName?: string) {
        this._logger.debug('ValuesService -- loading restrictions');
        const restrictions = await firstValueFrom(this._http.get<RestrictionsDto>(this._baseUrl + 'restrictions'));
        this.$restrictions.set(restrictions);
    }
}
