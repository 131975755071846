import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {AppState, AuthService, LogoutOptions, RedirectLoginOptions} from "@auth0/auth0-angular";
import {firstValueFrom} from "rxjs";
import {Capacitor} from "@capacitor/core";
import {Browser} from "@capacitor/browser";
import {AlertService} from "./alert.service";

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    private _isNative = Capacitor.isNativePlatform();

    constructor(
        private auth: AuthService,
        private _alert: AlertService
    ) {
    }

    logout() {
        const returnTo = this._isNative ? environment.auth0CallbackUrl : environment.logoutUrl;
        const logoutOptions: LogoutOptions = {logoutParams: {returnTo}};
        if (this._isNative) logoutOptions.openUrl = this._openUrl;
        this.auth.logout(logoutOptions);
    }

    async login() {
        try {
            console.log('login: isNative=', this._isNative);
            const redirectLoginOptions: RedirectLoginOptions<AppState> = {
                openUrl: this._isNative ? this._openUrl : undefined,
            };
            await firstValueFrom(this.auth.loginWithRedirect(redirectLoginOptions));
        } catch (e) {
            await this._alert.warn(`Login failed, ${e.message}`);
        }
    }

    private async _openUrl(url: string) {
        await Browser.open({url, windowName: '_self'});
    }
}
