import {Injectable, signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MenuItemForListDto} from "../_dtos/menu-items/menu-items-for-list-dto";
import {environment} from "../../environments/environment";
import {firstValueFrom, lastValueFrom, Subject} from "rxjs";
import {ErrorHandlerService} from "./error-handler.service";

@Injectable({
    providedIn: 'root'
})
export class MenuItemsService {
    private _baseUrl = environment.apiUrl + 'menuItems/';

    $menuItems = signal<MenuItemForListDto[] | undefined>(undefined);

    constructor(
        private _http: HttpClient,
        private _eh: ErrorHandlerService
    ) {
    }

    async loadMenuItemsAsync() {
        try {
            const menuItems = await firstValueFrom(this._http.get<MenuItemForListDto[]>(this._baseUrl));
            this.$menuItems.set(menuItems);
        } catch (e) {
            await this._eh.handle(e);
        }
    }

    async updateMenuItem(id: number, menuItem: MenuItemForListDto) {
        return lastValueFrom(this._http.put<MenuItemForListDto>(this._baseUrl + id, menuItem));
    }

    async uploadPhoto(photo: File, type: 'Primary' | 'Thumbnail') {
        const formData = new FormData();
        formData.append('photo', photo);
        formData.append('photoType', type);

        return lastValueFrom(this._http.post<{ url: string }>(this._baseUrl + 'photo', formData));
    }
}
