import {Injectable} from '@angular/core';
import {AlertController, ToastButton, ToastController} from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(
        private _toastController: ToastController,
        private _alertController: AlertController
    ) {
    }

    async inform(message: string) {
        const toast = await this._toastController.create({
            message,
            duration: 3000,
            color: "secondary",
            cssClass: 'text-center'
        });

        await toast.present();
    }

    async warn(message: string) {
        const toast = await this._toastController.create({
            message,
            duration: 3000,
            color: "warning",
            cssClass: 'text-center'
        });

        await toast.present();
    }

    async strongWarn(header: string, message: string) {
        const alert = await this._alertController.create({
            header,
            subHeader: message,
            buttons: [
                {
                    text: 'OK',
                    role: 'confirm',
                },
            ],
        });

        await alert.present();
    }

    async success(message: string, buttons: ToastButton[] = []) {
        const toast = await this._toastController.create({
            message,
            duration: 3000,
            color: "success",
            cssClass: 'text-center',
            buttons
        });

        await toast.present();
    }

    async confirm(message: string): Promise<'confirm' | 'cancel' | string> {
        const alert = await this._alertController.create({
            header: message,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'OK',
                    role: 'confirm',
                },
            ],
        });

        await alert.present();

        const {role} = await alert.onWillDismiss();
        return role;
    }
}
