import {Component, Input, OnInit} from '@angular/core';
import {MenuItemForListDto} from "../../../_dtos/menu-items/menu-items-for-list-dto";
import {AlertService} from "../../../services/alert.service";
import {CartService} from "../../../services/cart.service";
import {IonicModule, ModalController, NavController, ToastButton} from "@ionic/angular";
import {MenuItemsService} from "../../../services/menu-items.service";
import {constants} from "../../../shared/constants/variables";
import {LoadingSkeletonTextComponent} from "../../loading-skeleton-text/loading-skeleton-text.component";
import {CommonModule} from "@angular/common";
import {ErrorHandlerService} from "../../../services/error-handler.service";

@Component({
    selector: 'app-menu-item-details',
    templateUrl: './menu-item-details.component.html',
    standalone: true,
    imports: [IonicModule, CommonModule, LoadingSkeletonTextComponent]
})
export class MenuItemDetailsComponent implements OnInit {
    menuItem: MenuItemForListDto | null = null;
    quantity: number = 0;
    @Input({required: true}) menuItemId: number;

    constructor(
        private _alert: AlertService,
        private _cart: CartService,
        private _navController: NavController,
        private _menuItemsService: MenuItemsService,
        private _modal: ModalController,
        private _eh: ErrorHandlerService
    ) {
    }

    async ngOnInit() {
        if (!this._menuItemsService.$menuItems()?.length) {
            await this._menuItemsService.loadMenuItemsAsync();
        }

        this.quantity = this._cart.getQuantity(this.menuItemId);
        this.quantity = this.quantity > 0 ? this.quantity : 1;

        const menuItem = this._menuItemsService.$menuItems()?.find(x => x.id === this.menuItemId);


        if (!menuItem) {
            this._alert.warn('Menu item not found');
            this._modal.dismiss(null, 'cancel');
            return null;
        }

        this.menuItem = menuItem;
    }

    incrementQuantity() {
        this.quantity++;
    }

    decrementQuantity() {
        if (this.quantity <= 0) return;
        this.quantity--;
    }

    async confirm() {
        if (!this.menuItem) return;

        const previousQuantity = this._cart.getQuantity(this.menuItemId);
        if (previousQuantity === this.quantity) {
            this._modal.dismiss(null, 'confirm');
            return;
        }

        if (this.quantity === 0) {
            const response = await this._alert.confirm('This will remove the item from your cart. Are you sure you want to continue?');
            if (response !== 'confirm') return;
        }

        this._cart.updateCartQuantity(this.menuItem, this.quantity);
        this._modal.dismiss(null, 'confirm');
    }

    cancel() {
        this._modal.dismiss(null, 'cancel');
    }

    getPrimaryPhoto(menuItem: MenuItemForListDto) {
        return menuItem.photos.find(x => x.type === 'Primary')?.url ?? constants.defaultPrimaryUrl;
    }
}
