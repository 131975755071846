import {CapacitorConfig} from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'com.yanzidelivery',
  appName: 'Yanzi Delivery',
  webDir: '../api/wwwroot',
  server: {
    androidScheme: 'http',
  }
};

export default config;
