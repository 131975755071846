import {ResolveFn} from '@angular/router';
import {inject} from "@angular/core";
import {ValuesService} from "../services/values.service";

export const restrictionsResolver: ResolveFn<boolean> = async (route, state) => {
    const valuesService = inject(ValuesService);
    if (valuesService.$restrictions()) return true;
    await valuesService.loadRestrictionsAsync();
    return !!valuesService.$restrictions();
};
