import {Component, OnInit} from '@angular/core';
import {IonicModule, NavController} from "@ionic/angular";

import {HeaderCartStatusComponent} from "../header-cart-status/header-cart-status.component";
import {AuthStatusComponent} from "../auth-status/auth-status.component";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {LoadingService} from "../../../services/loading.service";
import {AsyncPipe} from "@angular/common";

@Component({
    selector: 'app-main-header',
    templateUrl: './main-header.component.html',
    standalone: true,
    imports: [IonicModule, HeaderCartStatusComponent, AuthStatusComponent, RouterLink, RouterLinkActive, AsyncPipe]
})
export class MainHeaderComponent implements OnInit {
    showLoading = this._loading.isLoading.asReadonly();

    constructor(
        private _loading: LoadingService
    ) {
    }

    ngOnInit() {
    }
}
