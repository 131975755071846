import {Component, OnInit} from '@angular/core';
import {HeroTextComponent} from "./hero-text/hero-text.component";
import {HeroFeatureMenuItemComponent} from "./hero-feature-menu-item/hero-feature-menu-item.component";
import {IonicModule} from "@ionic/angular";
import {constants} from "../../shared/constants/variables";
import {LoadingSkeletonTextComponent} from "../loading-skeleton-text/loading-skeleton-text.component";
import {CommonModule} from "@angular/common";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    standalone: true,
    imports: [IonicModule, HeroTextComponent, HeroFeatureMenuItemComponent, LoadingSkeletonTextComponent, CommonModule],
})
export class HomeComponent implements OnInit {
    imageLoaded = false;

    constructor() {
    }

    ngOnInit() {
    }

    get logoSrc() {
        return `${constants.basePhotoFolder}/Yanzi_Logo_with_Pot.webp`
    }

    onImageLoad() {
        this.imageLoaded = true;
    }
}
