import {effect, Injectable, signal} from '@angular/core';
import {StoreAssignments} from "../_models/store-assignments";
import {lastValueFrom} from "rxjs";
import {OutOfStockDto} from "../_dtos/status-dtos/out-of-stock-dto";
import {environment} from "../../environments/environment";
import {LoggerService} from "./logger.service";
import {HttpClient} from "@angular/common/http";
import {SelectedAddressService} from "./selected-address.service";
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop";

@Injectable({
    providedIn: 'root'
})
export class InventoryService {
    private _baseUrl = environment.apiUrl + 'status/v1/';

    $outOfStockItems = signal<OutOfStockDto[] | undefined>(undefined);

    constructor(
        private _http: HttpClient,
        private _logger: LoggerService,
        private _selectedAddressService: SelectedAddressService
    ) {
        toObservable(this._selectedAddressService.$storeAssignment)
            .pipe(takeUntilDestroyed())
            .subscribe(async storeName => {
                this._logger.debug('InventoryService -- got new store assignment from selected address service:', storeName);
                await this.loadOutOfStockItems(storeName);
            });
    }

    async loadOutOfStockItems(storeName: StoreAssignments | undefined) {
        this._logger.debug('InventoryService -- updating out of stock items');
        if (storeName === 'none' || storeName == undefined) {
            this.$outOfStockItems.set([]);
            return;
        }

        const outOfStock = await lastValueFrom(this._http.get<OutOfStockDto[]>(this._baseUrl + 'OutOfStock/' + storeName));
        this.$outOfStockItems.set(outOfStock);
        this._logger.debug('InventoryService -- emitted out of stock items', outOfStock);
    }
}
