import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LiveChatWidgetComponent, LiveChatWidgetModule} from "@livechat/widget-angular";
import {IonicModule} from "@ionic/angular";
import {MainHeaderComponent} from "../../components/header/main-header/main-header.component";
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    standalone: true,
    imports: [LiveChatWidgetModule, IonicModule, MainHeaderComponent, NgIf]
})
export class SupportComponent implements OnInit {
    @ViewChild(LiveChatWidgetComponent) liveChat: LiveChatWidgetComponent;

    constructor() {
    }

    ngOnInit() {
        this.liveChat?.widget?.updateVisibility('maximized');
    }

    ionViewWillLeave() {
        this.liveChat?.widget?.updateVisibility('hidden');
    }

    chatReady() {
        this.liveChat.widget.updateVisibility('maximized');
    }
}
