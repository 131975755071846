import {Component, OnInit} from '@angular/core';
import {IonicModule} from "@ionic/angular";

@Component({
    selector: 'app-hero-text',
    templateUrl: './hero-text.component.html',
    standalone: true,
    imports: [IonicModule]
})
export class HeroTextComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
