import {Component, Input, OnInit} from '@angular/core';

import {IonicModule} from "@ionic/angular";

@Component({
    selector: 'app-loading-skeleton-text',
    templateUrl: './loading-skeleton-text.component.html',
    standalone: true,
    imports: [IonicModule]
})
export class LoadingSkeletonTextComponent implements OnInit {
    @Input() height = 50;
    @Input() count = 1;

    constructor() {
    }

    ngOnInit() {
    }

}
